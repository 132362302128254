import PostList from 'components/blog/PostList';
import { Layout, Seo } from 'components/common';
import { Intro } from 'components/sections';
import { graphql } from 'gatsby';
import React from 'react';

const Category = (props) => {
  const { data, pageContext } = props;
  const { edges: posts, totalCount } = data.allWpPost;
  const { title: siteTitle } = data.site.siteMetadata;
  const { name: category } = pageContext;
  // const title = `${totalCount} post${
  //   totalCount === 1 ? '' : 's'
  // } in the “${category}” category`
  const title = `“${category}”`;

  return (
    <Layout context={pageContext}>
      <Seo title={`Blog Topics | ${siteTitle}`} />
      <Intro title={`${title}`} subtitle={`${totalCount} Post(s) in Topic`} />
      <PostList posts={posts} />
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
